// import '@fortawesome/fontawesome-free/css/all.css';
// import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import minifyTheme from 'minify-css-string';
// import DatetimePicker from 'vuetify-datetime-picker';
import i18n from '../i18n';

Vue.use(Vuetify);

// Vue.use(DatetimePicker);

Vue.config.silent = process.env.NODE_ENV === 'production';

export default new Vuetify({
  // icons: {
  //   iconfont: 'mdi' || 'fa'
  // },
  theme: {
    options: {
      minifyTheme,
      themeCache: {
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value)
      }
    },
    dark: JSON.parse(localStorage.getItem('darkMode')) || false,
    themes: {
      light: {
        primary: '#333132'
      },
      dark: {
        primary: '#333132'
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});
