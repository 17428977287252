import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  order: {},
  orderLoading: false,

  orders: { data: [] },
  ordersLoading: false,

  ordersInsights: { data: [] },
  ordersInsightsLoading: false,

  updateOrder: {},
  updateOrderLoading: false,

  archivedOrder: {},
  archivedOrderLoading: false,

  paidOrder: {},
  paidOrderLoading: false,

  assignOrderToDriver: {},
  assignOrderToDriverLoading: false
};

// mutations
const mutations = {
  SET_ORDER: (state, order) => {
    state.order = order;
  },
  SET_ORDER_LOADING: (state, orderLoading) => {
    state.orderLoading = orderLoading;
  },

  SET_ORDERS: (state, orders) => {
    state.orders = orders;
  },
  SET_ORDERS_LOADING: (state, ordersLoading) => {
    state.ordersLoading = ordersLoading;
  },

  SET_ORDERS_INSIGHTS: (state, ordersInsights) => {
    state.ordersInsights = ordersInsights;
  },
  SET_ORDERS_INSIGHTS_LOADING: (state, ordersInsightsLoading) => {
    state.ordersInsightsLoading = ordersInsightsLoading;
  },

  SET_UPDATE_ORDER: (state, updateOrder) => {
    if (updateOrder?.order) {
      state.orders.data = state.orders.data.map(item => {
        if (item._id === updateOrder.order._id) {
          item.isPaid = updateOrder.order.isPaid;
          item.status = updateOrder.order.status;
          item.isArchive = updateOrder.order.isArchive;
          item.driver = updateOrder.order.driver;
        }
        return item;
      });
    }
    state.updateOrder = updateOrder;
  },
  SET_UPDATE_ORDER_LOADING: (state, updateOrderLoading) => {
    state.updateOrderLoading = updateOrderLoading;
  },

  SET_ARCHIVED_ORDER: (state, archivedOrder) => {
    if (archivedOrder?.order) {
      state.orders.data = state.orders.data.filter(item => item._id !== archivedOrder.order._id);
    }
  },
  SET_ARCHIVED_ORDER_LOADING: (state, archivedOrderLoading) => {
    state.archivedOrderLoading = archivedOrderLoading;
  },

  SET_PAID_ORDER: (state, paidOrder) => {
    if (paidOrder?.order) {
      state.orders.data = state.orders.data.map(item => {
        if (item._id === paidOrder.order._id) {
          item.isPaid = paidOrder.order.isPaid;
        }
        return item;
      });
    }
    state.paidOrder = paidOrder;
  },
  SET_PAID_ORDER_LOADING: (state, paidOrderLoading) => {
    state.paidOrderLoading = paidOrderLoading;
  },

  SET_ASSIGN_ORDER_TO_DRIVER: (state, assignOrderToDriver) => {
    state.assignOrderToDriver = assignOrderToDriver;
  },
  SET_ASSIGN_ORDER_TO_DRIVER_LOADING: (state, assignOrderToDriverLoading) => {
    state.assignOrderToDriverLoading = assignOrderToDriverLoading;
  }
};

// actions
const actions = {
  onFetchOrder: async ({ commit, dispatch }, orderId) => {
    try {
      commit('SET_ORDER_LOADING', true);
      const res = await apiClient.get(`/orders/${orderId}`);
      if (res.status === 200) {
        commit('SET_ORDER', res.data.order);
        commit('SET_ORDER_LOADING', false);
      }
    } catch (error) {
      commit('SET_ORDER', { data: [] });
      commit('SET_ORDER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchOrders: async ({ commit, dispatch }, filtersData) => {
    commit('SET_ORDERS_LOADING', true);
    let statuses = '';
    let deliveryTypes = '';
    let url = '/orders';
    if (Array.isArray(filtersData.status)) {
      filtersData.status?.forEach(item => {
        statuses += `status[in]=${item}&`;
      });
      delete filtersData.status;
    }
    if (Array.isArray(filtersData.deliveryType)) {
      filtersData.deliveryType.forEach(item => {
        deliveryTypes += `deliveryType[in]=${item}&`;
      });
      delete filtersData.deliveryType;
    }
    if (statuses && !deliveryTypes) url += `?${statuses}`;
    if (!statuses && deliveryTypes) url += `?${deliveryTypes}`;
    if (statuses && deliveryTypes) url += `?${statuses}${deliveryTypes}`;
    try {
      const res = await apiClient.get(url, {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_ORDERS', res.data);
        commit('SET_ORDERS_LOADING', false);
      }
    } catch (error) {
      commit('SET_ORDERS', { data: [] });
      commit('SET_ORDERS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchOrdersInsights: async ({ commit, dispatch }, filtersData) => {
    commit('SET_ORDERS_INSIGHTS_LOADING', true);
    let statuses = '';
    let deliveryTypes = '';
    let url = '/orders/insights';
    if (Array.isArray(filtersData.status)) {
      filtersData.status?.forEach(item => {
        statuses += `status[in]=${item}&`;
      });
      delete filtersData.status;
    }
    if (Array.isArray(filtersData.deliveryType)) {
      filtersData.deliveryType.forEach(item => {
        deliveryTypes += `deliveryType[in]=${item}&`;
      });
      delete filtersData.deliveryType;
    }
    if (statuses && !deliveryTypes) url += `?${statuses}`;
    if (!statuses && deliveryTypes) url += `?${deliveryTypes}`;
    if (statuses && deliveryTypes) url += `?${statuses}${deliveryTypes}`;
    try {
      const res = await apiClient.get(url, {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_ORDERS_INSIGHTS', res.data);
        commit('SET_ORDERS_INSIGHTS_LOADING', false);
      }
    } catch (error) {
      commit('SET_ORDERS_INSIGHTS', { data: [] });
      commit('SET_ORDERS_INSIGHTS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateOrder: async ({ commit, dispatch }, orderData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_ORDER', {});
      commit('SET_UPDATE_ORDER_LOADING', true);
      const res = await apiClient.put(`/orders/${orderData._id}`, orderData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_ORDER', res.data);
        commit('SET_UPDATE_ORDER_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_ORDER', {});
      commit('SET_UPDATE_ORDER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onToggleArchivedOrder: async ({ commit, dispatch }, orderData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      // commit('SET_ARCHIVED_ORDER', {});
      commit('SET_ARCHIVED_ORDER_LOADING', true);
      const res = await apiClient.put(`/orders/${orderData._id}/archived`, orderData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_ARCHIVED_ORDER', res.data);
        commit('SET_ARCHIVED_ORDER_LOADING', false);
      }
    } catch (error) {
      commit('SET_ARCHIVED_ORDER', {});
      commit('SET_ARCHIVED_ORDER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onTogglePaidOrder: async ({ commit, dispatch }, orderData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      // commit('SET_PAID_ORDER', {});
      commit('SET_PAID_ORDER_LOADING', true);
      const res = await apiClient.put(`/orders/${orderData._id}/paid`, orderData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_PAID_ORDER', res.data);
        commit('SET_PAID_ORDER_LOADING', false);
      }
    } catch (error) {
      commit('SET_PAID_ORDER', {});
      commit('SET_PAID_ORDER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onAssignOrderToDriver: async ({ commit, dispatch }, orderData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_ASSIGN_ORDER_TO_DRIVER', {});
      commit('SET_ASSIGN_ORDER_TO_DRIVER_LOADING', true);
      const res = await apiClient.put(`/orders/${orderData._id}/driver`, {
        driverId: orderData.driverId
      });
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_ORDER', res.data);
        commit('SET_ASSIGN_ORDER_TO_DRIVER', res.data);
        commit('SET_ASSIGN_ORDER_TO_DRIVER_LOADING', false);
      }
    } catch (error) {
      commit('SET_ASSIGN_ORDER_TO_DRIVER', {});
      commit('SET_ASSIGN_ORDER_TO_DRIVER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingOrder: state =>
    state.orderLoading || state.ordersLoading || state.ordersInsightsLoading || state.paidOrderLoading || state.updateOrderLoading || state.archivedOrderLoading || state.assignOrderToDriverLoading,
  insightsOrder: state => {
    const insight = {
      totalPaidOrders: 0,
      totalRatedOrders: 0,
      totalPickedOrders: 0,
      totalUnpickedOrders: 0,
      totalCanceledOrders: 0,
      totalRejectedOrders: 0,
      totalArchivedOrders: 0,
      totalOrdersDiscount: 0,
      totalConfirmedOrders: 0,
      totalDeliveredOrders: 0,
      totalUndeliveredOrders: 0,
      totalOrdersDeliveryPrice: 0,
      totalOrdersAfterDiscount: 0,
      totalOutForDeliveryOrders: 0,
      totalOrdersBeforeDiscount: 0,
      totalSameDayDeliveryOrders: 0,
      totalScheduledPickupOrders: 0,
      totalScheduledDeliveryOrders: 0,
      totalPurchasers: 0
    };
    if (state.ordersInsights.total) {
      state.ordersInsights?.data.forEach(order => {
        insight.totalOrdersDiscount += order.totalDiscount;
        insight.totalOrdersAfterDiscount += order.totalAfterDiscount;
        insight.totalOrdersDeliveryPrice += order.totalDeliveryPrice;
        insight.totalOrdersBeforeDiscount += order.totalBeforeDiscount;
        if (order.isPaid) insight.totalPaidOrders += 1;
        if (order.isRated) insight.totalRatedOrders += 1;
        if (order.isArchived) insight.totalArchivedOrders += 1;
        if (order.status === 'picked') insight.totalPickedOrders += 1;
        if (order.status === 'unpicked') insight.totalUnpickedOrders += 1;
        if (order.status === 'rejected') insight.totalRejectedOrders += 1;
        if (order.status === 'canceled') insight.totalCanceledOrders += 1;
        if (order.status === 'confirmed') insight.totalConfirmedOrders += 1;
        if (order.status === 'delivered') insight.totalDeliveredOrders += 1;
        if (order.status === 'undelivered') insight.totalUndeliveredOrders += 1;
        if (order.status === 'outForDelivery' && order.isArchived === false) insight.totalOutForDeliveryOrders += 1;
        if (order.deliveryType === 'sameDayDelivery') insight.totalSameDayDeliveryOrders += 1;
        if (order.deliveryType === 'scheduledDelivery') insight.totalScheduledDeliveryOrders += 1;
        if (order.deliveryType === 'scheduledPickup') insight.totalScheduledPickupOrders += 1;
      });
      insight.totalPurchasers = [...new Set(state.ordersInsights?.data.map(item => item.user))].length;
    }
    return insight;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
