import i18n from '../../i18n';
import { get, apiClient } from '../../utils';

// initial state
const state = {
  config: {},
  configLoading: false,

  updateConfig: {},
  updateConfigLoading: false
};

// mutations
const mutations = {
  SET_CONFIG: (state, config) => {
    state.config = config;
  },
  SET_CONFIG_LOADING: (state, configLoading) => {
    state.configLoading = configLoading;
  },

  SET_UPDATE_CONFIG: (state, updateConfig) => {
    state.updateConfig = updateConfig;
  },
  SET_UPDATE_CONFIG_LOADING: (state, updateConfigLoading) => {
    state.updateConfigLoading = updateConfigLoading;
  }
};

// actions
const actions = {
  onFetchConfig: async ({ commit, dispatch }) => {
    try {
      // dispatch('notify/hide', null, { root: true });
      commit('SET_CONFIG', {});
      commit('SET_CONFIG_LOADING', true);
      const res = await apiClient.get(`/configs`);
      if (res.status === 200) {
        commit('SET_CONFIG', res.data.config);
        commit('SET_CONFIG_LOADING', false);
      }
    } catch (error) {
      commit('SET_CONFIG', {});
      commit('SET_CONFIG_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateConfig: async ({ commit, dispatch }, configData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_CONFIG', {});
      commit('SET_UPDATE_CONFIG_LOADING', true);
      const res = await apiClient.put(`/configs/${configData._id}`, configData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_CONFIG', res.data);
        commit('SET_UPDATE_CONFIG_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_CONFIG', {});
      commit('SET_UPDATE_CONFIG_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingConfig: state => state.configLoading || state.updateConfigLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
