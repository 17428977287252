const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "appLayout" */ '@/layout/AuthLayout'),
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
      },
      {
        path: '/privacy-and-policy/ar',
        name: 'privacyAndPolicyAr',
        component: () => import(/* webpackChunkName: "privacyAndPolicyAr" */ '@/views/PrivacyAndPolicyAr.vue')
      },
      {
        path: '/privacy-and-policy/en',
        name: 'privacyAndPolicyEn',
        component: () => import(/* webpackChunkName: "privacyAndPolicyEn" */ '@/views/PrivacyAndPolicyEn.vue')
      }
    ]
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "appLayout" */ '@/layout/AppLayout'),
    redirect: '/app/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
      },
      {
        path: 'insights',
        name: 'insights',
        component: () => import(/* webpackChunkName: "insights" */ '@/views/insight/Insight.vue'),
        children: [
          {
            path: 'filter',
            name: 'insights.filter',
            component: () => import(/* webpackChunkName: "insightFilterModal" */ '@/views/insight/InsightFilterModal.vue')
          }
        ]
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "categoryList" */ '@/views/category/CategoryList.vue'),
        children: [
          {
            path: 'show/:categoryId',
            name: 'products.show',
            component: () => import(/* webpackChunkName: "showProducts" */ '@/views/category/ShowProducts.vue'),
            props: true
          }
        ]
      },
      {
        path: 'categories/new',
        name: 'category.new',
        component: () => import(/* webpackChunkName: "pageNew" */ '@/views/category/CategoryEditor.vue')
      },
      {
        path: 'categories/edit/:categoryId',
        name: 'category.edit',
        component: () => import(/* webpackChunkName: "categoryEdit" */ '@/views/category/CategoryEditor.vue'),
        props: true
      },
      {
        path: 'pages',
        name: 'pages',
        component: () => import(/* webpackChunkName: "pageList" */ '@/views/page/PageList.vue')
      },
      {
        path: 'pages/new',
        name: 'page.new',
        component: () => import(/* webpackChunkName: "pageNew" */ '@/views/page/PageEditor.vue')
      },
      {
        path: 'pages/edit/:pageId',
        name: 'page.edit',
        component: () => import(/* webpackChunkName: "pageEdit" */ '@/views/page/PageEditor.vue'),
        props: true
      },
      {
        path: 'quizzes',
        name: 'quizzes',
        component: () => import(/* webpackChunkName: "quizList" */ '@/views/quiz/QuizList.vue'),
        children: [
          {
            path: 'notify/:quizId',
            name: 'quiz.notify',
            component: () => import(/* webpackChunkName: "quizNotifyModal" */ '@/views/quiz/QuizNotifyModal.vue'),
            props: true
          },
          {
            path: 'winner/:quizId',
            name: 'quiz.showWinner',
            component: () => import(/* webpackChunkName: "winnerNotifyModal" */ '@/views/quiz/WinnerNotifyModal.vue'),
            props: true
          }
        ]
      },
      {
        path: 'quizzes/new',
        name: 'quiz.new',
        component: () => import(/* webpackChunkName: "quizNew" */ '@/views/quiz/QuizEditor.vue')
      },
      {
        path: 'quizzes/duplicate/:quizId',
        name: 'quiz.duplicate',
        component: () => import(/* webpackChunkName: "quizEdit" */ '@/views/quiz/QuizEditor.vue'),
        props: true
      },
      {
        path: 'quizzes/answers/:quizId',
        name: 'answers',
        component: () => import(/* webpackChunkName: "answerList" */ '@/views/answer/AnswerList.vue'),
        props: true,
        children: [
          {
            path: 'notify-winner/:quizId',
            name: 'answers.notifyWinner',
            component: () => import(/* webpackChunkName: "winnerNotifyModal" */ '@/views/answer/WinnerNotifyModal.vue'),
            props: true
          }
        ]
      },
      {
        path: 'blogs',
        name: 'blogs',
        component: () => import(/* webpackChunkName: "blogList" */ '@/views/blog/BlogList.vue')
      },
      {
        path: 'blogs/new',
        name: 'blog.new',
        component: () => import(/* webpackChunkName: "blogNew" */ '@/views/blog/BlogEditor.vue')
      },
      {
        path: 'blogs/edit/:blogId',
        name: 'blog.edit',
        component: () => import(/* webpackChunkName: "blogEdit" */ '@/views/blog/BlogEditor.vue'),
        props: true
      },
      {
        path: 'products',
        name: 'products',
        component: () => import(/* webpackChunkName: "productList" */ '@/views/product/ProductList.vue'),
        children: [
          {
            path: 'notify/:productId',
            name: 'product.notify',
            component: () => import(/* webpackChunkName: "productNotifyModal" */ '@/views/product/ProductNotifyModal.vue'),
            props: true
          }
        ]
      },
      {
        path: 'products/new',
        name: 'product.new',
        component: () => import(/* webpackChunkName: "productNew" */ '@/views/product/ProductEditor.vue')
      },
      {
        path: 'products/edit/:productId',
        name: 'product.edit',
        component: () => import(/* webpackChunkName: "productEdit" */ '@/views/product/ProductEditor.vue'),
        props: true
      },
      {
        path: 'locations',
        name: 'locations',
        component: () => import(/* webpackChunkName: "locationList" */ '@/views/location/LocationList.vue'),
        children: [
          {
            path: 'new',
            name: 'location.new',
            component: () => import(/* webpackChunkName: "locationNew" */ '@/views/location/LocationEditor.vue')
          },
          {
            path: 'edit/:locationId',
            name: 'location.edit',
            component: () => import(/* webpackChunkName: "locationEdit" */ '@/views/location/LocationEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "userList" */ '@/views/user/UserList.vue'),
        children: [
          {
            path: 'set-avatar/:driverId/:userAvatar?',
            name: 'user.setAvatar',
            component: () => import(/* webpackChunkName: "setUserAvatarModal" */ '@/views/user/setUserAvatarModal.vue'),
            props: true
          }
        ]
      },
      {
        path: 'drivers',
        name: 'drivers',
        component: () => import(/* webpackChunkName: "driverList" */ '@/views/driver/DriverList.vue'),
        children: [
          {
            path: 'set-avatar/:driverId/:driverAvatar?',
            name: 'driver.setAvatar',
            component: () => import(/* webpackChunkName: "setDriverAvatarModal" */ '@/views/driver/setDriverAvatarModal.vue'),
            props: true
          }
        ]
      },
      {
        path: 'coupons',
        name: 'coupons',
        component: () => import(/* webpackChunkName: "couponList" */ '@/views/coupon/CouponList.vue'),
        children: [
          {
            path: 'new',
            name: 'coupon.new',
            component: () => import(/* webpackChunkName: "couponNew" */ '@/views/coupon/CouponEditor.vue')
          },
          {
            path: 'edit/:couponId',
            name: 'coupon.edit',
            component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/coupon/CouponEditor.vue'),
            props: true
          },
          {
            path: 'notify/:couponId',
            name: 'coupon.notify',
            component: () => import(/* webpackChunkName: "couponNotifyModal" */ '@/views/coupon/CouponNotifyModal.vue'),
            props: true
          }
        ]
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import(/* webpackChunkName: "orderList" */ '@/views/order/OrderList.vue'),
        children: [
          {
            path: 'show/:orderId',
            name: 'order.show',
            component: () => import(/* webpackChunkName: "showOrder" */ '@/views/order/ShowOrder.vue'),
            props: true
          },
          {
            path: 'set/:orderId',
            name: 'order.setOrder',
            component: () => import(/* webpackChunkName: "setOrder" */ '@/views/order/SetOrder.vue'),
            props: true
          },
          {
            path: ':orderId/driver/:driverId?',
            name: 'order.setDriver',
            component: () => import(/* webpackChunkName: "setDriver" */ '@/views/order/SetDriver.vue'),
            props: true
          },
          {
            path: 'notify/:orderId/:userId/:userToken/:userLocale',
            name: 'order.notify',
            component: () => import(/* webpackChunkName: "rateOrderNotifyModal" */ '@/views/order/RateOrderNotifyModal.vue'),
            props: true
          },
          {
            path: 'edit/:orderId',
            name: 'order.edit',
            component: () => import(/* webpackChunkName: "editOrder" */ '@/views/order/EditOrder.vue'),
            props: true
          }
        ]
      },
      {
        path: 'archived-orders',
        name: 'order.archived',
        component: () => import(/* webpackChunkName: "archivedOrders" */ '@/views/order/ArchivedOrderList.vue'),
        children: [
          {
            path: 'show/:orderId',
            name: 'archived-orders.show',
            component: () => import(/* webpackChunkName: "showOrder" */ '@/views/order/ShowOrder.vue'),
            props: true
          }
        ]
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import(/* webpackChunkName: "notificationList" */ '@/views/notification/NotificationList.vue'),
        children: [
          {
            path: 'new',
            name: 'notification.new',
            component: () => import(/* webpackChunkName: "notificationNew" */ '@/views/notification/NotificationEditor.vue')
          }
        ]
      },
      {
        path: 'configurations',
        name: 'configurations',
        component: () => import(/* webpackChunkName: "configurationsView" */ '@/views/config/ConfigsView.vue')
      },
      {
        path: 'configurations/edit/:configId',
        name: 'configuration.edit',
        component: () => import(/* webpackChunkName: "configurationsEdit" */ '@/views/config/ConfigsEditor.vue'),
        props: true
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "appLayout" */ '@/layout/AuthLayout'),
    children: [
      {
        name: '404 Error',
        path: '',
        component: () => import(/* webpackChunkName: "404-error" */ '@/views/Error')
      }
    ]
  }
];

export default routes;
