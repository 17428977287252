import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  quiz: {},
  quizLoading: false,

  drawWinner: {},
  drawWinnerLoading: false,

  quizzes: {},
  quizzesLoading: false,

  createQuiz: {},
  createQuizLoading: false,

  updateQuiz: {},
  updateQuizLoading: false,

  deleteQuizLoading: false,

  activateQuizLoading: false
};

// mutations
const mutations = {
  SET_QUIZ: (state, quiz) => {
    state.quiz = quiz;
  },
  SET_QUIZ_LOADING: (state, quizLoading) => {
    state.quizLoading = quizLoading;
  },

  SET_DRAW_WINNER: (state, drawWinner) => {
    state.quizzes.data = state.quizzes.data.map(item => {
      if (item?._id === drawWinner?.quiz?._id) {
        item = { ...item, winner: drawWinner.quiz.winner };
      }
      return item;
    });
  },
  SET_DRAW_WINNER_LOADING: (state, drawWinnerLoading) => {
    state.drawWinnerLoading = drawWinnerLoading;
  },

  SET_QUIZZES: (state, quizzes) => {
    state.quizzes = {
      ...quizzes,
      data: quizzes.data.map(quiz => ({
        ...quiz,
        photos: quiz.photos.sort((a, b) => b.main - a.main)
      }))
    };
  },
  SET_QUIZZES_LOADING: (state, quizzesLoading) => {
    state.quizzesLoading = quizzesLoading;
  },

  SET_CREATE_QUIZ: (state, createQuiz) => {
    state.createQuiz = createQuiz;
  },
  SET_CREATE_QUIZ_LOADING: (state, createQuizLoading) => {
    state.createQuizLoading = createQuizLoading;
  },

  SET_UPDATE_QUIZ: (state, updateQuiz) => {
    state.updateQuiz = updateQuiz;
  },
  SET_UPDATE_QUIZ_LOADING: (state, updateQuizLoading) => {
    state.updateQuizLoading = updateQuizLoading;
  },

  SET_DELETE_QUIZ: (state, quiz) => {
    state.quizzes.data = state.quizzes.data.filter(item => item._id !== quiz._id);
  },
  SET_DELETE_QUIZ_LOADING: (state, deleteQuizLoading) => {
    state.deleteQuizLoading = deleteQuizLoading;
  },

  SET_ACTIVATE_QUIZ: (state, quiz) => {
    state.quizzes.data = state.quizzes.data.map(item => {
      if (item._id === quiz._id) {
        item = { ...item, isActive: quiz.isActive };
      }
      return item;
    });
  },
  SET_ACTIVATE_QUIZ_LOADING: (state, activateQuizLoading) => {
    state.activateQuizLoading = activateQuizLoading;
  }
};

// actions
const actions = {
  onFetchQuiz: async ({ commit, dispatch }, quizId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_QUIZ', {});
      commit('SET_QUIZ_LOADING', true);
      const res = await apiClient.get(`/quizzes/${quizId}`);
      if (res.status === 200) {
        commit('SET_QUIZ', res.data.quiz);
        commit('SET_QUIZ_LOADING', false);
      }
    } catch (error) {
      commit('SET_QUIZ', {});
      commit('SET_QUIZ_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchQuizzes: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_QUIZZES_LOADING', true);
      const res = await apiClient.get('/quizzes', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_QUIZZES', res.data);
        commit('SET_QUIZZES_LOADING', false);
      }
    } catch (error) {
      commit('SET_QUIZZES', []);
      commit('SET_QUIZZES_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDrawWinner: async ({ commit, dispatch }, quizId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DRAW_WINNER', {});
      commit('SET_DRAW_WINNER_LOADING', true);
      const res = await apiClient.post(`/quizzes/${quizId}/winner`, null);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DRAW_WINNER', res.data);
        commit('SET_DRAW_WINNER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DRAW_WINNER', {});
      commit('SET_DRAW_WINNER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreateQuiz: async ({ commit, dispatch }, quizData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_QUIZ', {});
      commit('SET_CREATE_QUIZ_LOADING', true);
      const res = await apiClient.post('/quizzes', quizData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_QUIZ', res.data);
        commit('SET_CREATE_QUIZ_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_QUIZ', {});
      commit('SET_CREATE_QUIZ_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateQuiz: async ({ commit, dispatch }, quizData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_QUIZ', {});
      commit('SET_UPDATE_QUIZ_LOADING', true);
      const res = await apiClient.put(`/quizzes/${quizData._id}`, quizData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_QUIZ', res.data);
        commit('SET_UPDATE_QUIZ_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_QUIZ', {});
      commit('SET_UPDATE_QUIZ_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteQuiz: async ({ commit, dispatch }, quizId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_QUIZ_LOADING', true);
      const res = await apiClient.delete(`/quizzes/${quizId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_QUIZ', res.data.quiz);
        commit('SET_DELETE_QUIZ_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_QUIZ', null);
      commit('SET_DELETE_QUIZ_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onActivateQuiz: async ({ commit, dispatch }, quizDate) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_ACTIVATE_QUIZ_LOADING', true);
      const res = await apiClient.put(`/quizzes/${quizDate._id}/activate`, quizDate);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_ACTIVATE_QUIZ', res.data.quiz);
        commit('SET_ACTIVATE_QUIZ_LOADING', false);
      }
    } catch (error) {
      commit('SET_ACTIVATE_QUIZ', null);
      commit('SET_ACTIVATE_QUIZ_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingQuiz: state =>
    state.quizLoading || state.quizzesLoading || state.drawWinnerLoading || state.createQuizLoading || state.updateQuizLoading || state.deleteQuizLoading || state.activateQuizLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
