import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../vuex/store';

Vue.use(VueRouter);

let userRole;

const checkLoggedIn = async () => {
  if (!store.getters['auth/loggedIn']) {
    await store.dispatch('auth/tryAutoLogin');
  }
  userRole = store.getters['auth/userRole'];
  return store.getters['auth/loggedIn'];
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  switch (to.name) {
    case 'privacyAndPolicyAr':
      return next();
    case 'privacyAndPolicyEn':
      return next();
    case 'login':
      if (await checkLoggedIn()) {
        return next('/app');
      }
      return next();
    case 'insights':
    case 'insights.filter':
    case 'categories':
    case 'category.new':
    case 'category.edit':
    case 'pages':
    case 'page.new':
    case 'page.edit':
    case 'products':
    case 'products.show':
    case 'product.notify':
    case 'product.new':
    case 'product.edit':
    case 'blogs':
    case 'blog.new':
    case 'blog.edit':
    case 'quizzes':
    case 'quiz.new':
    case 'quiz.edit':
    case 'locations':
    case 'location.new':
    case 'location.edit':
    case 'users':
    case 'user.setAvatar':
    case 'drivers':
    case 'driver.setAvatar':
    case 'coupons':
    case 'coupon.new':
    case 'coupon.edit':
    case 'coupon.notify':
    case 'order.setDriver':
    case 'order.notify':
    case 'order.archived':
    case 'archived-orders.show':
    case 'notifications':
    case 'notification.new':
    case 'configurations':
    case 'configuration.edit':
      if (await checkLoggedIn()) {
        if (userRole === 'admin') {
          return next();
        }
        return next('/app/home');
      }
      return next('/login');
    default:
      if (await checkLoggedIn()) {
        return next();
      }
      return next('/login');
  }
});

export default router;
