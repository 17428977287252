import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  currentUserData: null,
  currentUserDataLoading: false
};

// mutations
const mutations = {
  SET_CURRENT_USER_DATE: (state, currentUserData) => {
    state.currentUserData = currentUserData;
  },
  SET_CURRENT_USER_DATA_LOADING: (state, currentUserDataLoading) => {
    state.currentUserDataLoading = currentUserDataLoading;
  }
};

// actions
const actions = {
  tryAutoLogin: async ({ commit, dispatch }) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CURRENT_USER_DATA_LOADING', true);
      const res = await apiClient.get('/auth/me');
      if (res.data?.user && res.status === 200 && (res.data?.user?.isAdmin || res.data?.user?.isDriver)) {
        commit('SET_CURRENT_USER_DATE', res.data.user);
        commit('SET_CURRENT_USER_DATA_LOADING', false);
      } else {
        commit('SET_CURRENT_USER_DATE', null);
        commit('SET_CURRENT_USER_DATA_LOADING', false);
        const notify = { message: i18n.t('required.onlyAdminsOrDrivers') };
        dispatch('notify/show', notify, { root: true });
      }
    } catch (error) {
      commit('SET_CURRENT_USER_DATE', null);
      commit('SET_CURRENT_USER_DATA_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onLogin: async ({ commit, dispatch }, authData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CURRENT_USER_DATA_LOADING', true);
      const res = await apiClient.post('/auth/login', {
        phone: authData.phone,
        password: authData.password
      });
      if (res.status === 200 && res.data?.token && (res.data?.user?.isAdmin || res.data?.user?.isDriver)) {
        commit('SET_CURRENT_USER_DATE', res.data.user);
        commit('SET_CURRENT_USER_DATA_LOADING', false);
      } else {
        commit('SET_CURRENT_USER_DATE', null);
        commit('SET_CURRENT_USER_DATA_LOADING', false);
        const notify = { message: i18n.t('required.onlyAdmins') };
        dispatch('notify/show', notify, { root: true });
      }
    } catch (error) {
      commit('SET_CURRENT_USER_DATA_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        dispatch('notify/show', notify, { root: true });
      }
    }
  },

  onLogout: async ({ commit, dispatch }) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CURRENT_USER_DATA_LOADING', true);
      await apiClient.get('/auth/logout');
      setTimeout(() => {
        commit('SET_CURRENT_USER_DATA_LOADING', false);
        commit('SET_CURRENT_USER_DATE', null);
      }, 1000);
    } catch (error) {
      commit('SET_CURRENT_USER_DATE', null);
      commit('SET_CURRENT_USER_DATA_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        dispatch('notify/show', notify, { root: true });
      }
    }
  }
};

// getters
const getters = {
  loggedIn: state => !!state.currentUserData,
  currentUserData: state => state.currentUserData,
  currentUserDataLoading: state => state.currentUserDataLoading,
  userRole: state => {
    if (state.currentUserData?.isAdmin) {
      return 'admin';
    } else if (state.currentUserData?.isDriver) {
      return 'driver';
    } else {
      return 'client';
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
