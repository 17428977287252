import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueFileAgent from 'vue-file-agent';
import VueHtmlToPaper from 'vue-html-to-paper';
import 'vue-file-agent/dist/vue-file-agent.css';

import App from './App.vue';
import router from './router';
import store from './vuex/store';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import './plugins/meta';

Vue.config.productionTip = false;

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: ['https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css', 'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css', 'https://unpkg.com/kidlat-css/css/kidlat.css']
};

Vue.use(VueCompositionAPI);

Vue.use(VueHtmlToPaper, options);

Vue.use(VueFileAgent);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');
