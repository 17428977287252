import qs from 'qs';
import axios from 'axios';
import { apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  fileUploading: false,

  fileUploadUrl: {},
  fileUploadUrlLoading: false,

  fileUploadStatus: null,
  fileUploadLoading: false
};

// mutations
const mutations = {
  SET_FILE_UPLOADING: (state, fileUploading) => {
    state.fileUploading = fileUploading;
  },

  SET_FILE_UPLOAD_URL: (state, fileUploadUrl) => {
    state.fileUploadUrl = fileUploadUrl;
  },
  SET_FILE_UPLOAD_URL_LOADING: (state, fileUploadUrlLoading) => {
    state.fileUploadUrlLoading = fileUploadUrlLoading;
  },

  SET_FILE_UPLOAD_STATUS: (state, fileUploadStatus) => {
    state.fileUploadStatus = fileUploadStatus;
  },
  SET_FILE_UPLOAD_LOADING: (state, fileUploadLoading) => {
    state.fileUploadLoading = fileUploadLoading;
  }
};

// actions
const actions = {
  onFileUpload: async ({ state, commit, dispatch }, file) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_FILE_UPLOADING', true);
    await dispatch('s3Sign', file);
    if (state.fileUploadUrl && state.fileUploadUrl.signedRequest) {
      await dispatch('uploadToS3', {
        url: state.fileUploadUrl.signedRequest,
        file
      });
    }
    commit('SET_FILE_UPLOADING', false);
  },

  s3Sign: async ({ commit, dispatch }, { name, type }) => {
    console.log('type', type);
    try {
      commit('SET_FILE_UPLOAD_URL', {});
      commit('SET_FILE_UPLOAD_URL_LOADING', true);
      const res = await apiClient.get(`/files/sign`, {
        params: {
          'file-name': name,
          'file-type': type
        },
        paramsSerializer: params => qs.stringify(params)
      });
      commit('SET_FILE_UPLOAD_URL', res.data);
      commit('SET_FILE_UPLOAD_URL_LOADING', false);
    } catch (error) {
      commit('SET_FILE_UPLOAD_URL_LOADING', false);
      if (error.response !== undefined) {
        const notify = { message: i18n.t('labels.somethingWrong') };
        dispatch('notify/show', notify, { root: true });
      }
    }
  },

  uploadToS3: async ({ commit, dispatch }, { url, file }) => {
    try {
      commit('SET_FILE_UPLOAD_STATUS', null);
      commit('SET_FILE_UPLOAD_LOADING', true);
      const res = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        }
      });
      commit('SET_FILE_UPLOAD_STATUS', res.status);
      commit('SET_FILE_UPLOAD_LOADING', false);
    } catch (error) {
      commit('SET_FILE_UPLOAD_LOADING', false);
      if (error.response !== undefined) {
        const notify = { message: i18n.t('labels.somethingWrong') };
        dispatch('notify/show', notify, { root: true });
      }
    }
  }
};

// getters
const getters = {
  loadingFileUpload: state => state.fileUploadUrlLoading || state.fileUploadLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
