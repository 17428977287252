import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  drivers: {},
  driversLoading: false,

  updateDriverAvatar: {},
  updateDriverAvatarLoading: false,

  deleteDriverLoading: false
};

// mutations
const mutations = {
  SET_DRIVERS: (state, drivers) => {
    const newDrivers = {
      ...drivers,
      data: drivers.data.map(el => {
        const insight = {
          totalPaidOrders: 0,
          totalRatedOrders: 0,
          totalPickedOrders: 0,
          totalUnpickedOrders: 0,
          totalCanceledOrders: 0,
          totalRejectedOrders: 0,
          totalArchivedOrders: 0,
          totalOrdersDiscount: 0,
          totalConfirmedOrders: 0,
          totalDeliveredOrders: 0,
          totalUndeliveredOrders: 0,
          totalOrdersDeliveryPrice: 0,
          totalOrdersAfterDiscount: 0,
          totalOutForDeliveryOrders: 0,
          totalOrdersBeforeDiscount: 0,
          totalSameDayDeliveryOrders: 0,
          totalScheduledPickupOrders: 0,
          totalScheduledDeliveryOrders: 0
        };
        el.orders.forEach(order => {
          if (order.isPaid) insight.totalPaidOrders += 1;
          if (order.status === 'delivered') insight.totalDeliveredOrders += 1;
          if (order.status === 'undelivered') insight.totalUndeliveredOrders += 1;
          if (order.status === 'outForDelivery') insight.totalOutForDeliveryOrders += 1;
        });
        return {
          ...el,
          totalOrders: el.orders.length,
          totalPaidOrders: insight.totalPaidOrders,
          totalDeliveredOrders: insight.totalDeliveredOrders,
          totalUndeliveredOrders: insight.totalUndeliveredOrders,
          totalOutForDeliveryOrders: insight.totalOutForDeliveryOrders
        };
      })
    };
    state.drivers = newDrivers;
  },
  SET_DRIVERS_LOADING: (state, driversLoading) => {
    state.driversLoading = driversLoading;
  },

  SET_UPDATE_DRIVER_AVATAR: (state, updateDriverAvatar) => {
    state.updateDriverAvatar = updateDriverAvatar;
  },
  SET_UPDATE_DRIVER_AVATAR_LOADING: (state, updateDriverAvatarLoading) => {
    state.updateDriverAvatarLoading = updateDriverAvatarLoading;
  },

  SET_DELETE_DRIVER: (state, driverId) => {
    state.drivers.data = state.drivers.data.filter(item => item._id !== driverId);
  },
  SET_DELETE_DRIVER_LOADING: (state, deleteDriverLoading) => {
    state.deleteDriverLoading = deleteDriverLoading;
  }
};

// actions
const actions = {
  onFetchDriver: async ({ commit, dispatch }, driverId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DRIVER', {});
      commit('SET_DRIVER_LOADING', true);
      const res = await apiClient.get(`/drivers/${driverId}`);
      if (res.status === 200) {
        commit('SET_DRIVER', res);
        commit('SET_DRIVER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DRIVER', {});
      commit('SET_DRIVER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchDrivers: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_DRIVERS_LOADING', true);
      const res = await apiClient.get('/drivers', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_DRIVERS', res.data);
        commit('SET_DRIVERS_LOADING', false);
      }
    } catch (error) {
      commit('SET_DRIVERS', []);
      commit('SET_DRIVERS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateDriverAvatar: async ({ commit, dispatch }, { driverId, photo }) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_DRIVER_AVATAR', {});
      commit('SET_UPDATE_DRIVER_AVATAR_LOADING', true);
      const res = await apiClient.put(`/drivers/${driverId}/photo`, { photo });
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_DRIVER_AVATAR', res.data);
        commit('SET_UPDATE_DRIVER_AVATAR_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_DRIVER_AVATAR', {});
      commit('SET_UPDATE_DRIVER_AVATAR_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteDriver: async ({ commit, dispatch }, driverId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_DRIVER_LOADING', true);
      const res = await apiClient.delete(`/drivers/${driverId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_DRIVER', driverId);
        commit('SET_DELETE_DRIVER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_DRIVER', null);
      commit('SET_DELETE_DRIVER_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  driversLoading: state => state.driversLoading,
  loadingDriver: state => state.driversLoading || state.updateDriverAvatarLoading || state.deleteDriverLoading,
  drivers: state =>
    state.drivers?.data?.map(el => {
      if (el.user?.token) {
        return {
          _id: el._id,
          token: el.user.token,
          locale: el.user.locale,
          username: `${el.user.firstName} ${el.user.lastName} - ${el.user.phone}`
        };
      }
    })
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
