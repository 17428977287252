// initial state
const state = {
  notify: {
    show: false
  }
};

// mutations
const mutations = {
  SET_SHOW: (state, notify) => {
    state.notify = { show: true, ...notify };
  },
  SET_HIDE: state => {
    state.notify = { show: false };
  }
};

// actions
const actions = {
  show: ({ commit }, notify) => {
    commit('SET_SHOW', notify);
  },
  hide: ({ commit }) => {
    commit('SET_HIDE');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
