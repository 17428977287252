import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  page: {},
  pageLoading: false,

  pages: {},
  pagesLoading: false,

  createPage: {},
  createPageLoading: false,

  updatePage: {},
  updatePageLoading: false,

  deletePageLoading: false,

  activatePageLoading: false,

  swapPagesLoading: false
};

// mutations
const mutations = {
  SET_PAGE: (state, page) => {
    state.page = page;
  },
  SET_PAGE_LOADING: (state, pageLoading) => {
    state.pageLoading = pageLoading;
  },

  SET_PAGES: (state, pages) => {
    state.pages = {
      ...pages,
      data: pages.data.map(page => ({
        ...page,
        photos: page.photos.sort((a, b) => b.main - a.main)
      }))
    };
  },
  SET_PAGES_LOADING: (state, pagesLoading) => {
    state.pagesLoading = pagesLoading;
  },

  SET_CREATE_PAGE: (state, createPage) => {
    state.createPage = createPage;
  },
  SET_CREATE_PAGE_LOADING: (state, createPageLoading) => {
    state.createPageLoading = createPageLoading;
  },

  SET_UPDATE_PAGE: (state, updatePage) => {
    state.updatePage = updatePage;
  },
  SET_UPDATE_PAGE_LOADING: (state, updatePageLoading) => {
    state.updatePageLoading = updatePageLoading;
  },

  SET_DELETE_PAGE: (state, page) => {
    state.pages.data = state.pages.data.filter(item => item._id !== page._id);
  },
  SET_DELETE_PAGE_LOADING: (state, deletePageLoading) => {
    state.deletePageLoading = deletePageLoading;
  },

  SET_ACTIVATE_PAGE: (state, page) => {
    state.pages.data = state.pages.data.map(item => {
      if (item._id === page._id) {
        item = { ...item, isActive: page.isActive };
      }
      return item;
    });
  },
  SET_ACTIVATE_PAGE_LOADING: (state, activatePageLoading) => {
    state.activatePageLoading = activatePageLoading;
  },

  SET_SWAP_PAGES_LOADING: (state, swapPagesLoading) => {
    state.swapPagesLoading = swapPagesLoading;
  }
};

// actions
const actions = {
  onFetchPage: async ({ commit, dispatch }, pageId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_PAGE', {});
      commit('SET_PAGE_LOADING', true);
      const res = await apiClient.get(`/pages/${pageId}`);
      if (res.status === 200) {
        commit('SET_PAGE', res.data.page);
        commit('SET_PAGE_LOADING', false);
      }
    } catch (error) {
      commit('SET_PAGE', {});
      commit('SET_PAGE_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchPages: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_PAGES_LOADING', true);
      const res = await apiClient.get('/pages', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_PAGES', res.data);
        commit('SET_PAGES_LOADING', false);
      }
    } catch (error) {
      commit('SET_PAGES', []);
      commit('SET_PAGES_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreatePage: async ({ commit, dispatch }, pageData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_PAGE', {});
      commit('SET_CREATE_PAGE_LOADING', true);
      const res = await apiClient.post('/pages', pageData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_PAGE', res.data);
        commit('SET_CREATE_PAGE_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_PAGE', {});
      commit('SET_CREATE_PAGE_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdatePage: async ({ commit, dispatch }, pageData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_PAGE', {});
      commit('SET_UPDATE_PAGE_LOADING', true);
      const res = await apiClient.put(`/pages/${pageData._id}`, pageData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_PAGE', res.data);
        commit('SET_UPDATE_PAGE_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_PAGE', {});
      commit('SET_UPDATE_PAGE_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeletePage: async ({ commit, dispatch }, pageId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_PAGE_LOADING', true);
      const res = await apiClient.delete(`/pages/${pageId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_PAGE', res.data.page);
        commit('SET_DELETE_PAGE_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_PAGE', null);
      commit('SET_DELETE_PAGE_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onActivatePage: async ({ commit, dispatch }, pageDate) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_ACTIVATE_PAGE_LOADING', true);
      const res = await apiClient.put(`/pages/${pageDate._id}/activate`, pageDate);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_ACTIVATE_PAGE', res.data.page);
        commit('SET_ACTIVATE_PAGE_LOADING', false);
      }
    } catch (error) {
      commit('SET_ACTIVATE_PAGE', null);
      commit('SET_ACTIVATE_PAGE_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onSwapPages: async ({ commit, dispatch }, pageIds) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_SWAP_PAGES_LOADING', true);
      const res = await apiClient.put('/pages/swap', { pageIds });
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: i18n.locale === 'ar' ? res.data.messageAr : res.data.messageEn
          },
          { root: true }
        );
        commit('SET_SWAP_PAGES_LOADING', false);
      }
    } catch (error) {
      commit('SET_SWAP_PAGES_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingPage: state => state.pageLoading || state.pagesLoading || state.createPageLoading || state.updatePageLoading || state.deletePageLoading || state.activatePageLoading || state.swapPagesLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
