import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  ratings: {},
  ratingsLoading: false
};

// mutations
const mutations = {
  SET_RATINGS: (state, ratings) => {
    state.ratings = ratings;
  },
  SET_RATINGS_LOADING: (state, ratingsLoading) => {
    state.ratingsLoading = ratingsLoading;
  }
};

// actions
const actions = {
  onFetchRatings: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_RATINGS_LOADING', true);
      const res = await apiClient.get('/ratings', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_RATINGS', res.data);
        commit('SET_RATINGS_LOADING', false);
      }
    } catch (error) {
      commit('SET_RATINGS', []);
      commit('SET_RATINGS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingRating: state => state.ratingsLoading,
  insightsRating: state => {
    const insight = {
      totalDriversRatings: 0,
      totalProductsRatings: 0,
      totalServicesRatings: 0,
      averageDriversRating: 0,
      averageProductsRating: 0,
      averageServicesRating: 0
    };
    if (state.ratings.total) {
      state.ratings?.data.forEach(rating => {
        if (rating?.driverRating) {
          insight.totalDriversRatings += 1;
          insight.averageDriversRating += rating.driverRating;
        }
        if (rating?.productsRating) {
          insight.totalProductsRatings += 1;
          insight.averageProductsRating += rating.productsRating;
        }
        if (rating?.serviceRating) {
          insight.totalServicesRatings += 1;
          insight.averageServicesRating += rating.serviceRating;
        }
      });
    }
    return {
      ...insight,
      averageDriversRating: (insight.averageDriversRating / insight.totalDriversRatings).toFixed(2),
      averageProductsRating: (insight.averageProductsRating / insight.totalProductsRatings).toFixed(2),
      averageServicesRating: (insight.averageServicesRating / insight.totalServicesRatings).toFixed(2)
    };
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
