import axios from 'axios';
import store from '../vuex/store';
import i18n from '../i18n';

const { VUE_APP_BASE_URL, VUE_APP_API_VER } = process.env;

const axiosInstance = axios.create({
  timeout: 100000,
  withCredentials: true,
  baseURL: `${VUE_APP_BASE_URL}/${VUE_APP_API_VER}`
});

axiosInstance.defaults.headers.common.Accept = 'application/json';

axiosInstance.interceptors.request.use(
  response => response,
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    if (response?.data?.info?.code) {
      const { messageAr, messageEn } = response.data.info;
      response.data.info.message = i18n.locale === 'ar' ? messageAr : messageEn;
    }
    return response;
  },
  error => {
    if (error.response) {
      if (error.response?.data?.code) {
        const { messageAr, messageEn } = error.response.data;
        error.response.data.message = i18n.locale === 'ar' ? messageAr : messageEn;
      }
      return Promise.reject(error);
    } else {
      const notify = {
        type: 'lime darken-2',
        message: i18n.t('labels.internetConnectionError')
      };
      store.dispatch('notify/show', notify);
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
