import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  answers: {},
  answersLoading: false
};

// mutations
const mutations = {
  SET_ANSWERS: (state, answers) => {
    state.answers = answers;
  },
  SET_ANSWERS_LOADING: (state, answersLoading) => {
    state.answersLoading = answersLoading;
  }
};

// actions
const actions = {
  onFetchAnswers: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_ANSWERS_LOADING', true);
      const res = await apiClient.get('/answers', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_ANSWERS', res.data);
        commit('SET_ANSWERS_LOADING', false);
      }
    } catch (error) {
      commit('SET_ANSWERS', []);
      commit('SET_ANSWERS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingAnswer: state => state.answersLoading,
  insightAnswers: state => {
    const insight = {
      totalAnswers: 0,
      totalCorrectAnswers: 0,
      totalIncorrectAnswers: 0,
      totalSkippedAnswers: 0
    };
    if (state.answers.total) {
      insight.totalAnswers = state.answers.total;
      state.answers?.data.forEach(answer => {
        if (answer.userStatus === 'skipped') insight.totalSkippedAnswers += 1;
        if (answer.correct === true) insight.totalCorrectAnswers += 1;
        if (answer.correct === false && answer.userStatus === 'answered') insight.totalIncorrectAnswers += 1;
      });
    }
    return insight;
  },
  usersAnswers: state => {
    const users = [];
    if (state.answers?.data?.length) {
      state.answers.data.forEach(item => {
        if (item.quiz.winner === item.user._id) {
          item.user.isWinner = true;
        } else {
          item.user.isWinner = false;
        }
        item.user.correct = item.correct;
        item.user.userStatus = item.userStatus;
        users.push(item.user);
      });
      users.sort((userA, userB) => (userA.isWinner === userB.isWinner ? 0 : userA.isWinner ? -1 : 1));
    }
    return users;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
