// initial state
const state = {
  alerts: []
};

let nextId = 1;
// mutations
const mutations = {
  PUSH: (state, alert) => {
    state.alerts.push({
      ...alert,
      id: nextId++
    });
  },
  DELETE: (state, alertToRemove) => {
    state.alerts = state.alerts.filter(alert => alert.id !== alertToRemove.id);
  }
};

// actions
const actions = {
  add: ({ commit }, alert) => {
    commit('PUSH', alert);
  },
  remove: ({ commit }, alertToRemove) => {
    commit('DELETE', alertToRemove);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
