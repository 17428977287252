<template>
  <router-view />
</template>
<script>
const locale = localStorage.getItem('locale');
export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('projectName')}`,
      htmlAttrs: { lang: this.$vuetify.rtl ? 'ar' : 'en' },
      meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }]
    };
  },
  created() {
    if (locale) {
      this.$i18n.locale = locale;
      this.$vuetify.rtl = locale === 'ar';
      this.$store.commit('SET_LOCALE', locale);
    } else if (navigator.language) {
      this.$i18n.locale = navigator.language.substring(0, 2);
      this.$vuetify.rtl = navigator.language.substring(0, 2) === 'ar';
      this.$store.commit('SET_LOCALE', navigator.language.substring(0, 2));
    }
  }
};
</script>
