import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  users: {},
  usersLoading: false,

  usersTomorrowBirthday: {},
  usersTomorrowBirthdayLoading: false,

  updateUserAdmin: {},
  updateUserAdminLoading: false,

  deleteUserByAdmin: null,
  deleteUserByAdminLoading: false,

  totalUsers: 0,
  totalUsersLoading: false,

  totalNewUsers: 0,
  totalNewUsersLoading: false
};

// mutations
const mutations = {
  SET_USERS: (state, users) => {
    state.users = users;
  },
  SET_USERS_LOADING: (state, usersLoading) => {
    state.usersLoading = usersLoading;
  },

  SET_USERS_TOMORROW_BIRTHDAY: (state, usersTomorrowBirthday) => {
    state.usersTomorrowBirthday = usersTomorrowBirthday;
  },
  SET_USERS_TOMORROW_BIRTHDAY_LOADING: (state, usersTomorrowBirthdayLoading) => {
    state.usersTomorrowBirthdayLoading = usersTomorrowBirthdayLoading;
  },

  SET_UPDATE_USER_ADMIN: (state, updateUserAdmin) => {
    if (updateUserAdmin?.user) {
      state.users.data = state.users.data.map(item => {
        if (item._id === updateUserAdmin.user.id) {
          item.photo = updateUserAdmin.user.photo;
          item.isAdmin = updateUserAdmin.user.isAdmin;
          item.isActive = updateUserAdmin.user.isActive;
          item.isDriver = updateUserAdmin.user.isDriver;
          item.isSuspended = updateUserAdmin.user.isSuspended;
        }
        return item;
      });
    }
    state.updateUserAdmin = updateUserAdmin;
  },
  SET_UPDATE_USER_ADMIN_LOADING: (state, updateUserAdminLoading) => {
    state.updateUserAdminLoading = updateUserAdminLoading;
  },

  SET_DELETE_USER_BY_ADMIN: (state, deleteUserByAdmin) => {
    state.users.data = state.users.data.filter(item => item._id !== deleteUserByAdmin);
  },
  SET_DELETE_USER_BY_ADMIN_LOADING: (state, deleteUserByAdminLoading) => {
    state.deleteUserByAdminLoading = deleteUserByAdminLoading;
  },

  SET_TOTAL_USERS: (state, totalUsers) => {
    state.totalUsers = totalUsers;
  },
  SET_TOTAL_USERS_LOADING: (state, totalUsersLoading) => {
    state.totalUsersLoading = totalUsersLoading;
  },

  SET_TOTAL_NEW_USERS: (state, totalNewUsers) => {
    state.totalNewUsers = totalNewUsers;
  },
  SET_TOTAL_NEW_USERS_LOADING: (state, totalNewUsersLoading) => {
    state.totalNewUsersLoading = totalNewUsersLoading;
  }
};

// actions
const actions = {
  onFetchUsers: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_USERS_LOADING', true);
      const res = await apiClient.get('/users', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_USERS', res.data);
        commit('SET_USERS_LOADING', false);
      }
    } catch (error) {
      commit('SET_USERS', []);
      commit('SET_USERS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchUsersTomorrowBirthday: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_USERS_TOMORROW_BIRTHDAY_LOADING', true);
      const res = await apiClient.get('/users', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_USERS_TOMORROW_BIRTHDAY', res.data);
        commit('SET_USERS_TOMORROW_BIRTHDAY_LOADING', false);
      }
    } catch (error) {
      commit('SET_USERS_TOMORROW_BIRTHDAY', []);
      commit('SET_USERS_TOMORROW_BIRTHDAY_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateUserAdmin: async ({ commit, dispatch }, userAdminData) => {
    try {
      commit('SET_UPDATE_USER_ADMIN_LOADING', true);
      const res = await apiClient.put('/users/admin', userAdminData);
      if (res.status === 200) {
        commit('SET_UPDATE_USER_ADMIN', res.data);
        commit('SET_UPDATE_USER_ADMIN_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_USER_ADMIN', {});
      commit('SET_UPDATE_USER_ADMIN_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteUserByAdmin: async ({ commit, dispatch }, userId) => {
    try {
      commit('SET_DELETE_USER_BY_ADMIN_LOADING', true);
      const res = await apiClient.delete(`/users/${userId}`);
      if (res.status === 200) {
        commit('SET_DELETE_USER_BY_ADMIN', userId);
        commit('SET_DELETE_USER_BY_ADMIN_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_USER_BY_ADMIN', null);
      commit('SET_DELETE_USER_BY_ADMIN_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchTotalUsers: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_TOTAL_USERS_LOADING', true);
      const res = await apiClient.get('/users', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_TOTAL_USERS', res.data.total);
        commit('SET_TOTAL_USERS_LOADING', false);
      }
    } catch (error) {
      commit('SET_TOTAL_USERS', 0);
      commit('SET_TOTAL_USERS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchTotalNewUsers: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_TOTAL_NEW_USERS_LOADING', true);
      const res = await apiClient.get('/users', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_TOTAL_NEW_USERS', res.data.total);
        commit('SET_TOTAL_NEW_USERS_LOADING', false);
      }
    } catch (error) {
      commit('SET_TOTAL_USERS', 0);
      commit('SET_TOTAL_NEW_USERS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  usersLoading: state => state.usersLoading || state.usersTomorrowBirthdayLoading,
  loadingUser: state => state.usersLoading || state.updateUserAdminLoading || status.deleteUserByAdminLoading,
  loadingUserTomorrowBirthday: state => state.usersTomorrowBirthdayLoading,
  users: state =>
    state.users?.data?.map(el => {
      return {
        _id: el._id,
        token: el.token,
        locale: el.locale,
        username: `${el.firstName} ${el.lastName} - ${el.phone}`
      };
    })
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
