/* eslint-disable no-unused-vars */
const initialState = {
  active: false,
  icon: null,
  title: null,
  body: null,
  confirmColor: null,
  resolve: null,
  reject: null
};

const state = Object.assign({}, initialState);

const mutations = {
  SET_ACTIVATE: (state, payload) => {
    Object.assign(state, payload);
  },
  SET_DEACTIVATE: state => {
    state = Object.assign(state, initialState);
  }
};

const actions = {
  ask: ({ commit }, { icon, title, body, confirmColor }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ACTIVATE', {
        active: true,
        icon,
        title,
        body,
        confirmColor,
        resolve,
        reject
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
