import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  product: {},
  productLoading: false,

  products: {},
  productsLoading: false,

  createProduct: {},
  createProductLoading: false,

  updateProduct: {},
  updateProductLoading: false,

  deleteProductLoading: false,

  activateProductLoading: false
};

// mutations
const mutations = {
  SET_PRODUCT: (state, product) => {
    state.product = product;
  },
  SET_PRODUCT_LOADING: (state, productLoading) => {
    state.productLoading = productLoading;
  },

  SET_PRODUCTS: (state, products) => {
    state.products = {
      ...products,
      data: products.data.map(product => ({
        ...product,
        photos: product.photos.sort((a, b) => b.main - a.main)
      }))
    };
  },
  SET_PRODUCTS_LOADING: (state, productsLoading) => {
    state.productsLoading = productsLoading;
  },

  SET_CREATE_PRODUCT: (state, createProduct) => {
    state.createProduct = createProduct;
  },
  SET_CREATE_PRODUCT_LOADING: (state, createProductLoading) => {
    state.createProductLoading = createProductLoading;
  },

  SET_UPDATE_PRODUCT: (state, updateProduct) => {
    state.updateProduct = updateProduct;
  },
  SET_UPDATE_PRODUCT_LOADING: (state, updateProductLoading) => {
    state.updateProductLoading = updateProductLoading;
  },

  SET_DELETE_PRODUCT: (state, product) => {
    state.products.data = state.products.data.filter(item => item._id !== product._id);
  },
  SET_DELETE_PRODUCT_LOADING: (state, deleteProductLoading) => {
    state.deleteProductLoading = deleteProductLoading;
  },

  SET_ACTIVATE_PRODUCT: (state, product) => {
    state.products.data = state.products.data.map(item => {
      if (item._id === product._id) {
        item = { ...item, isActive: product.isActive };
      }
      return item;
    });
  },
  SET_ACTIVATE_PRODUCT_LOADING: (state, activateProductLoading) => {
    state.activateProductLoading = activateProductLoading;
  }
};

// actions
const actions = {
  onFetchProduct: async ({ commit, dispatch }, productId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_PRODUCT', {});
      commit('SET_PRODUCT_LOADING', true);
      const res = await apiClient.get(`/products/${productId}`);
      if (res.status === 200) {
        commit('SET_PRODUCT', res.data.product);
        commit('SET_PRODUCT_LOADING', false);
      }
    } catch (error) {
      commit('SET_PRODUCT', {});
      commit('SET_PRODUCT_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchProducts: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_PRODUCTS_LOADING', true);
      const res = await apiClient.get('/products', {
        params: { sort: '-updatedAt', ...filtersData },
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_PRODUCTS', res.data);
        commit('SET_PRODUCTS_LOADING', false);
      }
    } catch (error) {
      commit('SET_PRODUCTS', []);
      commit('SET_PRODUCTS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreateProduct: async ({ commit, dispatch }, productData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_PRODUCT', {});
      commit('SET_CREATE_PRODUCT_LOADING', true);
      const res = await apiClient.post('/products', productData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_PRODUCT', res.data);
        commit('SET_CREATE_PRODUCT_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_PRODUCT', {});
      commit('SET_CREATE_PRODUCT_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateProduct: async ({ commit, dispatch }, productData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_PRODUCT', {});
      commit('SET_UPDATE_PRODUCT_LOADING', true);
      const res = await apiClient.put(`/products/${productData._id}`, productData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_PRODUCT', res.data);
        commit('SET_UPDATE_PRODUCT_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_PRODUCT', {});
      commit('SET_UPDATE_PRODUCT_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteProduct: async ({ commit, dispatch }, productId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_PRODUCT_LOADING', true);
      const res = await apiClient.delete(`/products/${productId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_PRODUCT', res.data.product);
        commit('SET_DELETE_PRODUCT_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_PRODUCT', null);
      commit('SET_DELETE_PRODUCT_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onActivateProduct: async ({ commit, dispatch }, productDate) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_ACTIVATE_PRODUCT_LOADING', true);
      const res = await apiClient.put(`/products/${productDate._id}/activate`, productDate);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_ACTIVATE_PRODUCT', res.data.product);
        commit('SET_ACTIVATE_PRODUCT_LOADING', false);
      }
    } catch (error) {
      commit('SET_ACTIVATE_PRODUCT', null);
      commit('SET_ACTIVATE_PRODUCT_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingProduct: state => state.productLoading || state.productsLoading || state.createProductLoading || state.updateProductLoading || state.deleteProductLoading || state.activateProductLoading,
  insightsProduct: state => {
    const insight = { averageProductsRating: 0 };
    if (state.products.total) {
      state.products?.data.forEach(product => {
        if (product.ratingSum / product.ratingCount) {
          insight.averageProductsRating += product.ratingSum / product.ratingCount;
        }
      });
    }
    return {
      ...insight,
      averageProductsRating: (insight.averageProductsRating / state.products.total).toFixed(2)
    };
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
