import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  location: {},
  locationLoading: false,

  locations: {},
  locationsLoading: false,

  createLocation: {},
  createLocationLoading: false,

  updateLocation: {},
  updateLocationLoading: false,

  deleteLocationLoading: false
};

// mutations
const mutations = {
  SET_LOCATION: (state, location) => {
    state.location = location;
  },
  SET_LOCATION_LOADING: (state, locationLoading) => {
    state.locationLoading = locationLoading;
  },

  SET_LOCATIONS: (state, locations) => {
    state.locations = locations;
  },
  SET_LOCATIONS_LOADING: (state, locationsLoading) => {
    state.locationsLoading = locationsLoading;
  },

  SET_CREATE_LOCATION: (state, createLocation) => {
    // state.locations = {
    //   ...state.locations,
    //   data: [...state.locations.data, createLocation.location]
    // };
    state.createLocation = createLocation;
  },
  SET_CREATE_LOCATION_LOADING: (state, createLocationLoading) => {
    state.createLocationLoading = createLocationLoading;
  },

  SET_UPDATE_LOCATION: (state, updateLocation) => {
    state.updateLocation = updateLocation;
  },
  SET_UPDATE_LOCATION_LOADING: (state, updateLocationLoading) => {
    state.updateLocationLoading = updateLocationLoading;
  },

  SET_DELETE_LOCATION: (state, location) => {
    state.locations.data = state.locations.data.filter(item => item._id !== location._id);
  },
  SET_DELETE_LOCATION_LOADING: (state, deleteLocationLoading) => {
    state.deleteLocationLoading = deleteLocationLoading;
  }
};

// actions
const actions = {
  onFetchLocation: async ({ commit, dispatch }, locationId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_LOCATION', {});
      commit('SET_LOCATION_LOADING', true);
      const res = await apiClient.get(`/locations/${locationId}`);
      if (res.status === 200) {
        commit('SET_LOCATION', res.data.location);
        commit('SET_LOCATION_LOADING', false);
      }
    } catch (error) {
      commit('SET_LOCATION', {});
      commit('SET_LOCATION_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchLocations: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_LOCATIONS_LOADING', true);
      const res = await apiClient.get('/locations', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_LOCATIONS', res.data);
        commit('SET_LOCATIONS_LOADING', false);
      }
    } catch (error) {
      commit('SET_LOCATIONS', []);
      commit('SET_LOCATIONS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreateLocation: async ({ commit, dispatch }, locationData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_LOCATION', {});
      commit('SET_CREATE_LOCATION_LOADING', true);
      const res = await apiClient.post('/locations', locationData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_LOCATION', res.data);
        commit('SET_CREATE_LOCATION_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_LOCATION', {});
      commit('SET_CREATE_LOCATION_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateLocation: async ({ commit, dispatch }, locationData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_LOCATION', {});
      commit('SET_UPDATE_LOCATION_LOADING', true);
      const res = await apiClient.put(`/locations/${locationData._id}`, locationData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_LOCATION', res.data);
        commit('SET_UPDATE_LOCATION_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_LOCATION', {});
      commit('SET_UPDATE_LOCATION_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteLocation: async ({ commit, dispatch }, locationId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_LOCATION_LOADING', true);
      const res = await apiClient.delete(`/locations/${locationId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_LOCATION', res.data.location);
        commit('SET_DELETE_LOCATION_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_LOCATION', null);
      commit('SET_DELETE_LOCATION_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingLocation: state => state.locationLoading || state.locationsLoading || state.createLocationLoading || state.updateLocationLoading || state.deleteLocationLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
