import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  blog: {},
  blogLoading: false,

  blogs: {},
  blogsLoading: false,

  createBlog: {},
  createBlogLoading: false,

  updateBlog: {},
  updateBlogLoading: false,

  deleteBlogLoading: false,

  activateBlogLoading: false,

  swapBlogsLoading: false
};

// mutations
const mutations = {
  SET_BLOG: (state, blog) => {
    state.blog = blog;
  },
  SET_BLOG_LOADING: (state, blogLoading) => {
    state.blogLoading = blogLoading;
  },

  SET_BLOGS: (state, blogs) => {
    state.blogs = {
      ...blogs,
      data: blogs.data.map(blog => ({
        ...blog,
        photos: blog.photos.sort((a, b) => b.main - a.main)
      }))
    };
  },
  SET_BLOGS_LOADING: (state, blogsLoading) => {
    state.blogsLoading = blogsLoading;
  },

  SET_CREATE_BLOG: (state, createBlog) => {
    state.createBlog = createBlog;
  },
  SET_CREATE_BLOG_LOADING: (state, createBlogLoading) => {
    state.createBlogLoading = createBlogLoading;
  },

  SET_UPDATE_BLOG: (state, updateBlog) => {
    state.updateBlog = updateBlog;
  },
  SET_UPDATE_BLOG_LOADING: (state, updateBlogLoading) => {
    state.updateBlogLoading = updateBlogLoading;
  },

  SET_DELETE_BLOG: (state, blog) => {
    state.blogs.data = state.blogs.data.filter(item => item._id !== blog._id);
  },
  SET_DELETE_BLOG_LOADING: (state, deleteBlogLoading) => {
    state.deleteBlogLoading = deleteBlogLoading;
  },

  SET_ACTIVATE_BLOG: (state, blog) => {
    state.blogs.data = state.blogs.data.map(item => {
      if (item._id === blog._id) {
        item = { ...item, isActive: blog.isActive };
      }
      return item;
    });
  },
  SET_ACTIVATE_BLOG_LOADING: (state, activateBlogLoading) => {
    state.activateBlogLoading = activateBlogLoading;
  },

  SET_SWAP_BLOGS_LOADING: (state, swapBlogsLoading) => {
    state.swapBlogsLoading = swapBlogsLoading;
  }
};

// actions
const actions = {
  onFetchBlog: async ({ commit, dispatch }, blogId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_BLOG', {});
      commit('SET_BLOG_LOADING', true);
      const res = await apiClient.get(`/blogs/${blogId}`);
      if (res.status === 200) {
        commit('SET_BLOG', res.data.blog);
        commit('SET_BLOG_LOADING', false);
      }
    } catch (error) {
      commit('SET_BLOG', {});
      commit('SET_BLOG_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchBlogs: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_BLOGS_LOADING', true);
      const res = await apiClient.get('/blogs', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_BLOGS', res.data);
        commit('SET_BLOGS_LOADING', false);
      }
    } catch (error) {
      commit('SET_BLOGS', []);
      commit('SET_BLOGS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreateBlog: async ({ commit, dispatch }, blogData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_BLOG', {});
      commit('SET_CREATE_BLOG_LOADING', true);
      const res = await apiClient.post('/blogs', blogData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_BLOG', res.data);
        commit('SET_CREATE_BLOG_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_BLOG', {});
      commit('SET_CREATE_BLOG_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateBlog: async ({ commit, dispatch }, blogData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_BLOG', {});
      commit('SET_UPDATE_BLOG_LOADING', true);
      const res = await apiClient.put(`/blogs/${blogData._id}`, blogData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_BLOG', res.data);
        commit('SET_UPDATE_BLOG_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_BLOG', {});
      commit('SET_UPDATE_BLOG_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteBlog: async ({ commit, dispatch }, blogId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_BLOG_LOADING', true);
      const res = await apiClient.delete(`/blogs/${blogId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_BLOG', res.data.blog);
        commit('SET_DELETE_BLOG_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_BLOG', null);
      commit('SET_DELETE_BLOG_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onActivateBlog: async ({ commit, dispatch }, blogDate) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_ACTIVATE_BLOG_LOADING', true);
      const res = await apiClient.put(`/blogs/${blogDate._id}/activate`, blogDate);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_ACTIVATE_BLOG', res.data.blog);
        commit('SET_ACTIVATE_BLOG_LOADING', false);
      }
    } catch (error) {
      commit('SET_ACTIVATE_BLOG', null);
      commit('SET_ACTIVATE_BLOG_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onSwapBlogs: async ({ commit, dispatch }, blogIds) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_SWAP_BLOGS_LOADING', true);
      const res = await apiClient.put('/blogs/swap', { blogIds });
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: i18n.locale === 'ar' ? res.data.messageAr : res.data.messageEn
          },
          { root: true }
        );
        commit('SET_SWAP_BLOGS_LOADING', false);
      }
    } catch (error) {
      commit('SET_SWAP_BLOGS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingBlog: state => state.blogLoading || state.blogsLoading || state.createBlogLoading || state.updateBlogLoading || state.deleteBlogLoading || state.activateBlogLoading || state.swapBlogsLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
