import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  category: {},
  categoryLoading: false,

  categories: {},
  categoriesLoading: false,

  createCategory: {},
  createCategoryLoading: false,

  updateCategory: {},
  updateCategoryLoading: false,

  deleteCategoryLoading: false,

  activateCategoryLoading: false,

  swapCategoriesLoading: false
};

// mutations
const mutations = {
  SET_CATEGORY: (state, category) => {
    state.category = category;
  },
  SET_CATEGORY_LOADING: (state, categoryLoading) => {
    state.categoryLoading = categoryLoading;
  },

  SET_CATEGORIES: (state, categories) => {
    state.categories = {
      ...categories,
      data: categories.data.map(category => ({
        ...category,
        photos: category.photos.sort((a, b) => b.main - a.main)
      }))
    };
  },
  SET_CATEGORIES_LOADING: (state, categoriesLoading) => {
    state.categoriesLoading = categoriesLoading;
  },

  SET_CREATE_CATEGORY: (state, createCategory) => {
    state.createCategory = createCategory;
  },
  SET_CREATE_CATEGORY_LOADING: (state, createCategoryLoading) => {
    state.createCategoryLoading = createCategoryLoading;
  },

  SET_UPDATE_CATEGORY: (state, updateCategory) => {
    state.updateCategory = updateCategory;
  },
  SET_UPDATE_CATEGORY_LOADING: (state, updateCategoryLoading) => {
    state.updateCategoryLoading = updateCategoryLoading;
  },

  SET_DELETE_CATEGORY: (state, category) => {
    state.categories.data = state.categories.data.filter(item => item._id !== category._id);
  },
  SET_DELETE_CATEGORY_LOADING: (state, deleteCategoryLoading) => {
    state.deleteCategoryLoading = deleteCategoryLoading;
  },

  SET_ACTIVATE_CATEGORY: (state, category) => {
    state.categories.data = state.categories.data.map(item => {
      if (item._id === category._id) {
        item = { ...item, isActive: category.isActive };
      }
      return item;
    });
  },
  SET_ACTIVATE_CATEGORY_LOADING: (state, activateCategoryLoading) => {
    state.activateCategoryLoading = activateCategoryLoading;
  },

  SET_SWAP_CATEGORIES_LOADING: (state, swapCategoriesLoading) => {
    state.swapCategoriesLoading = swapCategoriesLoading;
  }
};

// actions
const actions = {
  onFetchCategory: async ({ commit, dispatch }, categoryId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CATEGORY', {});
      commit('SET_CATEGORY_LOADING', true);
      const res = await apiClient.get(`/categories/${categoryId}`);
      if (res.status === 200) {
        commit('SET_CATEGORY', res.data.category);
        commit('SET_CATEGORY_LOADING', false);
      }
    } catch (error) {
      commit('SET_CATEGORY', {});
      commit('SET_CATEGORY_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchCategories: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_CATEGORIES_LOADING', true);
      const res = await apiClient.get('/categories', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_CATEGORIES', res.data);
        commit('SET_CATEGORIES_LOADING', false);
      }
    } catch (error) {
      commit('SET_CATEGORIES', []);
      commit('SET_CATEGORIES_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreateCategory: async ({ commit, dispatch }, categoryData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_CATEGORY', {});
      commit('SET_CREATE_CATEGORY_LOADING', true);
      const res = await apiClient.post('/categories', categoryData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_CATEGORY', res.data);
        commit('SET_CREATE_CATEGORY_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_CATEGORY', {});
      commit('SET_CREATE_CATEGORY_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateCategory: async ({ commit, dispatch }, categoryData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_CATEGORY', {});
      commit('SET_UPDATE_CATEGORY_LOADING', true);
      const res = await apiClient.put(`/categories/${categoryData._id}`, categoryData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_CATEGORY', res.data);
        commit('SET_UPDATE_CATEGORY_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_CATEGORY', {});
      commit('SET_UPDATE_CATEGORY_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteCategory: async ({ commit, dispatch }, categoryId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_CATEGORY_LOADING', true);
      const res = await apiClient.delete(`/categories/${categoryId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_CATEGORY', res.data.category);
        commit('SET_DELETE_CATEGORY_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_CATEGORY', null);
      commit('SET_DELETE_CATEGORY_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onActivateCategory: async ({ commit, dispatch }, categoryDate) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_ACTIVATE_CATEGORY_LOADING', true);
      const res = await apiClient.put(`/categories/${categoryDate._id}/activate`, categoryDate);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_ACTIVATE_CATEGORY', res.data.category);
        commit('SET_ACTIVATE_CATEGORY_LOADING', false);
      }
    } catch (error) {
      commit('SET_ACTIVATE_CATEGORY', null);
      commit('SET_ACTIVATE_CATEGORY_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onSwapCategories: async ({ commit, dispatch }, categoryIds) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_SWAP_CATEGORIES_LOADING', true);
      const res = await apiClient.put('/categories/swap', { categoryIds });
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_SWAP_CATEGORIES_LOADING', false);
      }
    } catch (error) {
      commit('SET_SWAP_CATEGORIES_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingCategory: state =>
    state.categoryLoading ||
    state.categoriesLoading ||
    state.createCategoryLoading ||
    state.updateCategoryLoading ||
    state.deleteCategoryLoading ||
    state.activateCategoryLoading ||
    state.swapCategoriesLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
