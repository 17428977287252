import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

const strict = process.env.NODE_ENV !== 'production';

const plugins = strict ? [createLogger({})] : [];

Vue.use(Vuex);

const state = {
  drawer: null,
  showOrderDrawer: null,
  breadcrumbsItems: [],
  headerLinks: [
    {
      icon: 'mdi-logout',
      text: 'logout',
      url: '/login'
    }
  ],
  sidebarLinks: [
    {
      icon: 'mdi-home',
      text: 'home',
      name: 'home',
      url: '/app/home'
    },
    {
      icon: 'mdi-chart-bar',
      text: 'insight',
      name: 'insights',
      url: '/app/insights',
      subtitle: 'insightSubtitle'
    },
    {
      icon: 'mdi-file-document-edit',
      text: 'category',
      name: 'categories',
      url: '/app/categories',
      subtitle: 'categoriesSubtitle'
    },
    {
      icon: 'mdi-file-document-edit-outline',
      text: 'page',
      name: 'pages',
      url: '/app/pages',
      subtitle: 'pagesSubtitle'
    },
    {
      icon: 'mdi-blogger',
      text: 'blog',
      name: 'blogs',
      url: '/app/blogs',
      subtitle: 'blogsSubtitle'
    },
    {
      icon: 'fa fa-boxes',
      text: 'product',
      name: 'products',
      url: '/app/products',
      subtitle: 'productsSubtitle'
    },
    {
      icon: 'mdi-order-alphabetical-ascending',
      text: 'order',
      name: 'orders',
      url: '/app/orders',
      subtitle: 'ordersSubtitle'
    },
    {
      icon: 'mdi-order-alphabetical-ascending',
      text: 'yourDeliveryOrder',
      name: 'deliveryOrders',
      url: '/app/orders',
      subtitle: 'deliveryOrdersSubtitle'
    },
    {
      icon: 'mdi-archive-outline',
      text: 'archivedOrders',
      name: 'order.archived',
      url: '/app/archived-orders',
      subtitle: 'archivedOrdersSubtitle'
    },
    {
      icon: 'mdi-help',
      text: 'quiz',
      name: 'quizzes',
      url: '/app/quizzes',
      subtitle: 'quizzesSubtitle'
    },
    {
      icon: 'mdi-location-enter',
      text: 'location',
      name: 'locations',
      url: '/app/locations',
      subtitle: 'locationsSubtitle'
    },
    {
      icon: 'mdi-account-group',
      text: 'user',
      name: 'users',
      url: '/app/users',
      subtitle: 'usersSubtitle'
    },
    {
      icon: 'mdi-car',
      text: 'driver',
      name: 'drivers',
      url: '/app/drivers',
      subtitle: 'driversSubtitle'
    },
    {
      icon: 'mdi-ticket-percent-outline',
      text: 'coupon',
      name: 'coupons',
      url: '/app/coupons',
      subtitle: 'couponsSubtitle'
    },
    {
      icon: 'mdi-bell-outline',
      text: 'notification',
      name: 'notifications',
      url: '/app/notifications',
      subtitle: 'notificationsSubtitle'
    },
    {
      icon: 'mdi-cogs',
      text: 'configuration',
      name: 'configurations',
      url: '/app/configurations',
      subtitle: 'configurationsSubtitle'
    }
  ]
};

const mutations = {
  SET_LOCALE(state, locale) {
    localStorage.setItem('locale', locale);
    // axios.defaults.headers.common['X-localization'] = locale;
  },
  SET_DRAWER(state, drawer) {
    state.drawer = drawer;
  },
  SET_BREADCRUMBS(state, breadcrumbsItems) {
    state.breadcrumbsItems = breadcrumbsItems;
  },
  SET_SHOW_ORDER_DRAWER(state, showOrderDrawer) {
    state.showOrderDrawer = showOrderDrawer;
  }
};

const actions = {};

const getters = {};

const modulesFiles = require.context('./modules', true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins,
  modules
});
