import qs from 'qs';
import { get, apiClient } from '../../utils';
import i18n from '../../i18n';

// initial state
const state = {
  coupon: {},
  couponLoading: false,

  coupons: {},
  couponsLoading: false,

  createCoupon: {},
  createCouponLoading: false,

  updateCoupon: {},
  updateCouponLoading: false,

  deleteCouponLoading: false
};

// mutations
const mutations = {
  SET_COUPON: (state, coupon) => {
    state.coupon = coupon;
  },
  SET_COUPON_LOADING: (state, couponLoading) => {
    state.couponLoading = couponLoading;
  },

  SET_COUPONS: (state, coupons) => {
    state.coupons = coupons;
  },
  SET_COUPONS_LOADING: (state, couponsLoading) => {
    state.couponsLoading = couponsLoading;
  },

  SET_CREATE_COUPON: (state, createCoupon) => {
    state.createCoupon = createCoupon;
  },
  SET_CREATE_COUPON_LOADING: (state, createCouponLoading) => {
    state.createCouponLoading = createCouponLoading;
  },

  SET_UPDATE_COUPON: (state, updateCoupon) => {
    state.updateCoupon = updateCoupon;
  },
  SET_UPDATE_COUPON_LOADING: (state, updateCouponLoading) => {
    state.updateCouponLoading = updateCouponLoading;
  },

  SET_DELETE_COUPON: (state, coupon) => {
    state.coupons.data = state.coupons.data.map(item => {
      if (item._id === coupon._id) {
        item.isDeleted = true;
      }
      return item;
    });
  },
  SET_DELETE_COUPON_LOADING: (state, deleteCouponLoading) => {
    state.deleteCouponLoading = deleteCouponLoading;
  }
};

// actions
const actions = {
  onFetchCoupon: async ({ commit, dispatch }, couponId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_COUPON', {});
      commit('SET_COUPON_LOADING', true);
      const res = await apiClient.get(`/coupons/${couponId}`);
      if (res.status === 200) {
        commit('SET_COUPON', res.data.coupon);
      }
      commit('SET_COUPON_LOADING', false);
    } catch (error) {
      commit('SET_COUPON', {});
      commit('SET_COUPON_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onFetchCoupons: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_COUPONS_LOADING', true);
      const res = await apiClient.get('/coupons', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_COUPONS', res.data);
        commit('SET_COUPONS_LOADING', false);
      }
    } catch (error) {
      commit('SET_COUPONS', []);
      commit('SET_COUPONS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreateCoupon: async ({ commit, dispatch }, couponData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_COUPON', {});
      commit('SET_CREATE_COUPON_LOADING', true);
      const res = await apiClient.post('/coupons', couponData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_COUPON', res.data);
        commit('SET_CREATE_COUPON_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_COUPON', {});
      commit('SET_CREATE_COUPON_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onUpdateCoupon: async ({ commit, dispatch }, couponData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_UPDATE_COUPON', {});
      commit('SET_UPDATE_COUPON_LOADING', true);
      const res = await apiClient.put(`/coupons/${couponData._id}`, couponData);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_COUPON', res.data);
        commit('SET_UPDATE_COUPON_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_COUPON', {});
      commit('SET_UPDATE_COUPON_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onDeleteCoupon: async ({ commit, dispatch }, couponId) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_DELETE_COUPON_LOADING', true);
      const res = await apiClient.delete(`/coupons/${couponId}`);
      if (res.status === 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_COUPON', res.data.coupon);
        commit('SET_DELETE_COUPON_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_COUPON', null);
      commit('SET_DELETE_COUPON_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', i18n.t('labels.somethingWrong'))
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingCoupon: state => state.couponLoading || state.couponsLoading || state.createCouponLoading || state.updateCouponLoading || state.deleteCouponLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
